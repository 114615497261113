import React from "react";
import XAutoComplete from "./XAutoComplate"
import './style.css'

export default function Input(props) {
    var objectConstructor = ({}).constructor;
    // React.useEffect(() => {
    //     console.log("INSIDE ", props?.options)
    // }, [props?.options])
    if (props?.type == 'textarea') {
        return <textarea {...props} ></textarea>
    }
    if (props?.type == 'autocomplete') {
        return <XAutoComplete {...props} />
    }

    if (props?.type == 'select') {
        return <div class="did-floating-label-content">
            <select class="did-floating-input" {...props} >
                <option>Select {props?.label ? props?.label : props?.name}</option>
                {props?.options?.map((option) => objectConstructor == option.constructor ? <option value={option[props?.val]}>{option[props?.text]}</option> : <option>{option}</option>)}
            </select>
        </div>
    }
    if (props?.type === 'hidden') {
        return <input class="did-floating-input" {...props} />
    }
    return <div class="did-floating-label-content">
        <input class="did-floating-input" {...props} placeholder=" " autoComplete="false" />
        <label></label>
        <label class="did-floating-label">{props?.label ? props?.label : props?.name}</label>
    </div>

}