import { createAction } from "./saga";
export const AUTH_DATA = "auth";

// ---  Your Part Do not update above code----

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const ISLOGGED = "ISLOGGED";
export const GROUP = "GROUP";
export const MODULE = "MODULE";
export const DASHBOARD = "DASHBOARD";
export const USER = "USER";
export const CHART = "CHART";
export const DB_PLAN = "DB_PLAN";
export const DB_PLAN_UPDATE = "DB_PLAN_UPDATE";

export const PAYMENTS = "PAYMENTS";
export const LOAN_DETAILS = "LOAN_DETAILS";
// export const ASSETLIST = "ASSETLIST";
// export const ASSET_ALLOCATION = "ASSET_ALLOCATION";

export const SUB_TASKS = "SUB_TASKS";
export const REQUESTS = {
  ...createAction(LOGOUT, "", AUTH_DATA),
  ...createAction(ISLOGGED, "", AUTH_DATA),
  ...createAction(LOGIN, "token", AUTH_DATA),
  ...createAction(GROUP, "group", "group"),
  ...createAction(MODULE, "module", "module"),
  ...createAction(DASHBOARD, "dashboard", "dashboard"),
  ...createAction(USER, "user", "user"),
  ...createAction(CHART, "chart", "chart"),
  ...createAction(DB_PLAN, "db_plan_v2", "db_plan"),
  ...createAction(DB_PLAN_UPDATE, "db_plan_update", "db_plan_update"),
  ...createAction(PAYMENTS, "transactions", "payments", { message: true }),
  ...createAction(LOAN_DETAILS, "loan_search", "loan_details", { message: true }),
};
