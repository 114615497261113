export const cdate = () => {
    return new Date().toJSON().slice(0, 10)
}
export const getYYYMMDD = (date) => {
    try {
        let d = new Date(date)
        return "Invalid" === d ? date : new Date(date).toJSON().slice(0, 10)
    } catch (err) {
        return date
    }
}
export function isNumber(value) {
    if (typeof value === "string") {
        return !isNaN(value);
    }
    return !isNaN(value)
}
export function endOfMonth(date) {
    return getYYYMMDD(new Date(date.getFullYear(), date.getMonth() + 1, 0));
}