import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TablePagination from '@mui/material/TablePagination';
import {
  Grid,
  Snackbar,
  Alert,
  Box,
  Divider,
  Button,
  CircularProgress,
  Chip,
} from "@mui/material";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import XForm from "./inputs/XForm";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import EditNoteRoundedIcon from "@mui/icons-material/EditNoteRounded";
import Stack from "@mui/material/Stack";
import ControlPointRoundedIcon from "@mui/icons-material/ControlPointRounded";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { Cancel } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import { css as customCss } from '../content/Style'
import FilterListIcon from '@mui/icons-material/FilterList';
import ZFilter from "./inputs/ZFilter";

export default function ViewSave({
  title,
  dataList,
  keys,
  children,
  onSubmit,
  renderView,
  renderList,
  renderHead,
  onEdit,
  onDelete,
  onMore,
  element,
  elementCss,
  renderSearch,
  renderPagination,
  onSearch,
  style,
  height,
  pagination,
  onClickPagination,
  hideCols,
  hideMore,
  showKeys,
  css,
  onComplete,
  icon,
  images,
  showColumns,
  showDetailColumns,
  noRenderList,
  moreIcon,
  editIcon,
  deleteIcon,
  listIcon,
  handleChangeRowsPerPage,
  totalPaginationCount
}) {
  let ref = React.createRef();
  let refReset = React.createRef();
  const [fullScreen, setFullScreen] = React.useState(true);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(4);
  const [showModal, setShowModal] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [formData, setFormData] = React.useState(false);
  const [message, setMessage] = React.useState("This is the message");
  const [search, setSearch] = React.useState("");
  const [showDetails, setshowDetails] = React.useState(-1);

  const onPageChange = (event, newPageNumber)=>{
    console.log("PAGE NUMBER",newPageNumber)
    setPage(newPageNumber);
    if (onClickPagination){onClickPagination((newPageNumber))}

  }
  handleChangeRowsPerPage = handleChangeRowsPerPage?handleChangeRowsPerPage:()=>{}
  onMore = onMore ? onMore : (data) => { }
  const mi = moreIcon ? moreIcon : <ExpandLess
    sx={{ fontSize: 24 }}
    onClick={() => {
      setshowDetails(-1);
    }}
  />
  noRenderList = noRenderList ? noRenderList : () => <div style={{ textAlign: 'center' }}>No data available</div>
  elementCss = elementCss
    ? elementCss
    : {
      sx: {
        width: "100%",
        overflowY: "auto",
        height: height ? height : "70vh",
      },
    };
  hideCols = hideCols ? hideCols : [];
  hideMore = hideMore ? hideMore : [];
  css = css ? css : { rowColor: "#e9f0f4" };
  icon = icon ? icon : "avatar";
  images = images ? images : [];
  let data = dataList?.length > 0 ? dataList[0] : {};
  showColumns = showColumns ? showColumns : Object.keys(data);
  showDetailColumns = showDetailColumns ? showDetailColumns : Object.keys(data);
  onEdit = onEdit
    ? onEdit
    : (data, setShowModal, setLoading) => {
      setFormData(data);
      setShowModal(true);
    };
  onDelete = onDelete ? onDelete : (data, setLoading) => { };
  const ei = editIcon ? editIcon : <Edit
    variant={"primary"}
    sx={{ ...customCss.Btn?.Edit, cursor: "pointer"}}
  />
  const di = deleteIcon ? deleteIcon : <Cancel
    variant={"error"}
    title={"Delete"}
    sx={{ color: "#e95541", ...customCss?.Btn?.Delete, cursor: "pointer" }}
  />
  const renderExpand = (idx, data) => {
    return moreIcon ? <span onClick={() => { onMore(data) }}>{moreIcon}</span> : <span onClick={() => {
      setshowDetails(showDetails != idx ? idx : -1);
    }}>
      {
        showDetails != idx ?
          <ExpandMoreIcon
            sx={customCss.Btn}
          /> : mi
      }
    </span>
  }

  renderList = renderList
    ? renderList
    : (data, { index }) => {
      return (
        <>
          <div
            style={{
              background: css.rowColor,
              borderRadius: css.radius ? css.radius : 4,
              overflowX: 'auto'
            }}
          >
            <Stack direction="row" spacing={1} sx={{ maxWidth: "100%" }}>
              <ListItemButton sx={{ p: 1, minWidth: 45,maxWidth:50 }}>
                <Avatar width={20} src={data[icon]} />
              </ListItemButton>

              {showColumns.map((key, i) => {
                if (showKeys) {
                  return <div>{key}</div>;
                }
                if (icon === key) return;
                if (hideCols.indexOf(key) > -1) return;
                if (Array.isArray(data[key])) return;
                if (typeof data[key] === "object" && data[key] !== null)
                  return;
                return (
                  <Box
                    display="flex"
                    alignItems={"center"}
                    sx={{ pr: 1, width: "100%",maxWidth:200 }}
                  >
                    <Typography
                      sx={{ textOverflow: "ellipsis", fontSize: 13, maxWidth: 320 }}
                    >
                      {data[key]}
                    </Typography>
                  </Box>
                );
              })}
              <Box display="flex" alignContent={"end"} alignItems={"center"} sx={{ pr: 1,width:'100%' }}>
                <span onClick={() => {
                  onEdit(data, setShowModal, setLoading);
                }}>{ei}</span>
                <span
                  onClick={() => {
                    onDelete(data, setLoading);
                  }}
                >{di}</span>
                {renderExpand(index, data)}
              </Box>
            </Stack>

            <div style={{ paddingLeft: 8, paddingRight: 8 }}>
              {index == showDetails && (
                <div
                  style={{
                    background: css.detailColor ? css.detailColor : "#ebebeb",
                    borderRadius: 4,
                  }}
                >
                  {showDetailColumns.map((key, i) => {
                    if (!data[key]) return;
                    if (showKeys) {
                      return <div>{key}</div>;
                    }
                    if (icon === key) return;
                    if (hideMore.indexOf(key) > -1) return;
                    if (Array.isArray(data[key])) return;
                    if (typeof data[key] === "object" && data[key] !== null)
                      return;
                    return <div style={{ background: '#d9dcde', padding: 4, marginBottom: 4 }}><Typography>{key} : {data[key]}</Typography></div>;
                  })}
                </div>
              )}
            </div>
          </div>
          <Divider variant="inset" component="li" />
        </>
      );
    };
  onSearch = onSearch ? onSearch : (value) => { };

  renderHead = renderHead ? renderHead : (value) => <></>;

  renderSearch = renderSearch
    ? renderSearch
    : () => {
      return (<>
        <TextField
          inputProps={{
            style: {
              height,
              padding: "0 14px",
            },
          }}
          sx={{ border: "none", borderRadius: 4 }}
          value={search}
          onChange={(e) => {
            setSearch(e.target.value);
            onSearch(e.target.value);
          }}
          size="small"
        />
        <Button sx={{ fontSize: 12 }} size="small">Search</Button>
        <Divider orientation="vertical" sx={{ height: 14, marginLeft: 2, marginRight: 2 }} />
        <ZFilter icon={<FilterListIcon sx={{ fontSize: 14 }} customCss={customCss} />} />
      </>
      );
    };
  const parentEle = () => {
    return element ? element : List;
  };
  renderView = renderView
    ? renderView
    : (dataList, renderList) => {
      return React.createElement(
        parentEle(),
        elementCss,
        <>
          {renderHead()}
          {dataList &&
            dataList.map((data, i) => {
              return renderList(data, {
                index: i,
                setFormData: setFormData,
                setShowModal: setShowModal,
                setLoading: setLoading,
              });
            })
          }
          {(dataList && dataList.length == 0) && noRenderList()}
          {onComplete && onComplete()}
        </>
      );
    };
  const handleChange = (event, number) => {
    let url = pagination.next;
    if (pagination.previous) {
      url = pagination.previous;
    }
    url = url.split("=")[0];
    url = url + "=" + number;
    console.log("URL", url);
    if (onClickPagination)
      onClickPagination({ pagination: pagination, number: number, url: url });
  };

  renderPagination = renderPagination
    ? renderPagination
    : () => {
      return (
        <>
          {pagination && <TablePagination
          rowsPerPageOptions={[4]}
          component="div"
          count={totalPaginationCount}
          rowsPerPage={dataList?.length}
          page={page}
          onPageChange={onPageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />}
        </>
      );
    };

  React.useEffect(() => { }, [dataList]);
  const onSubmitIn = (e, data) => {
    if (onSubmit) {
      let r = onSubmit(e, data, setLoading, setMessage, (data1) => {
        setFormData(data1);
      });
      if (r) setShowModal(false)
    }
  };
  return (
    <>
      <Dialog
        open={showModal && children}
        aria-labelledby="responsive-dialog-title"
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{ p: 0.5, px: 1, ...customCss.Card.header }}
        >
          <Typography variant="caption">{title}</Typography>
          <Snackbar
            sx={{ display: "flex", justifyContent: "center" }}
            open={message != ""}
            autoHideDuration={2000}
            onClose={() => {
              setMessage("");
            }}
          >
            <Alert
              onClose={() => {
                setMessage("");
              }}
              severity="success"
              sx={{ width: "100%" }}
            >
              {message}
            </Alert>
          </Snackbar>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ bgcolor: "background.paper" }}>
            <XForm data={formData} onSubmit={onSubmitIn}>
              {children}
              <input
                type={"reset"}
                ref={refReset}
                style={{ display: "none" }}
              />
              <input type={"submit"} ref={ref} style={{ display: "none" }} />
            </XForm>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              refReset.current.click();
              setShowModal(false);
            }}
          >
            Cancel
          </Button>
          {loading && <CircularProgress sx={{ fontSize: 10 }} />}
          {!loading && (
            <Button
              onClick={() => {
                ref.current.click();
              }}
              autoFocus
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
      <div style={{ ...style, border: customCss?.Card?.header.border, borderRadius: customCss?.Card?.header.borderRadius }}>
        <Box
          display="flex"
          sx={{
            width: "100%",
            minWidth: 360,
            ...customCss.Card.header,
            p: 0.5,
            px: 1,
            mb: 0,
          }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="caption">
            {title}
          </Typography>
          {children && (
            <Typography variant="caption" sx={{ pt: 0.5 }}>
              <ControlPointRoundedIcon
                sx={{ cursor: "pointer", fontSize: 16 }}
                onClick={() => {
                  setFormData({});
                  setShowModal(true);
                }}
              />
            </Typography>
          )}
        </Box>
        <Box
          display="flex"
          alignItems={"center"}
          sx={{
            ...customCss.Card.body,
            px: 1,
            py: 1,
            width: "100%",
            minWidth: 360,
            mb: 0,
          }}
        >
          {renderSearch()}
        </Box>
        <Box
          display="flex"
          alignItems={"center"}
          alignContent={"center"}
        >
          {renderHead}
        </Box>
        <Box
          display="flex"
          sx={{
            ...customCss.Card.body,
            px: 0.5,
            py: 1,
            width: "100%",
            minWidth: 360,
            mb: 0,
            overflowX: 'auto'
          }}
        >
          {renderView(dataList, renderList)}
        </Box>
        {renderPagination(pagination)}
      </div>
    </>
  );
}
