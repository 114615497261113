import * as React from "react";
import { TextField } from "@mui/material";
import { Grid } from "@mui/material";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { REQUESTS } from "../../redux/types";
import { XAutoComplete, ViewSave } from "../../libs/Libs";
import CropImg from "../../libs/imgcrop/CropImg";
import ZTable, {
  ZTableHead,
  ZTableBody,
  ZTableToolbar,
} from "../../libs/table/ZTable";

export default function Payments() {
  let dispatch = useDispatch();
  let PaymentState = useSelector((state) => state.payments);
  let [paginate, setPaginate] = React.useState({});
  let [filter, setFilter] = React.useState([]);
  const [q, setQ] = React.useState({
    model: "user",
  });

  const init = (q) => {
    dispatch({ type: REQUESTS.PAYMENTS.GET.ACTION, payload: 0 });
    console.log("PAYMENTS SATE", PaymentState);
  };

  React.useEffect(() => {
    setQ({
      model: "user",
    });
    init(q);
  }, []);
  React.useEffect(() => {
    console.log("UPDATED", PaymentState);
    // setPaginate({ count: userState.data.count, next: userState.data.next, previous: userState.data.previous })
  }, [PaymentState]);

  const onSubmit = (e, data, setLoading, setMessage) => {
    setLoading(true);
    let action = data["id"] > 0 ? REQUESTS.PAYMENTS.PUT.ACTION : REQUESTS.PAYMENTS.POST.ACTION
    dispatch({ type: action, payload: data });
    setLoading(false);
    return true;
  };

  const onClickPagination = (num) => {
    dispatch({ type: REQUESTS.PAYMENTS.GET.ACTION, payload: `transactions/0/?page=${num}` });
  };
  // viewSave Customization
  const header = [
    { label: "SL.NO", key: "id", type: "number" },
    { label: "Initiate Date", key: "TxnInitDate", type: "number" },
    { label: "Completion Date", key: "TxnCompletionDate", type: "number" },
    { label: "MerchantTranId", key: "merchantTranId", type: "string" },
    { label: "TerminalId", key: "terminalId", type: "string" },
    { label: "PayerName", key: "PayerName", type: "string" },
    { label: "PayerAmount", key: "PayerAmount", type: "number" },
    { label: "PayerVA", key: "PayerVA", type: "string" },
    { label: "BankRRN", key: "BankRRN", type: "string" },
    { label: "MerchantId", key: "merchantId", type: "string" },
    { label: "TxnStatus", key: "TxnStatus", type: "string" },
    { label: "Reciept_no", key: "reciept_no", type: "string" },
    { label: "Reciept_date", key: "reciept_date", type: "number" },
    { label: "Reciept_amount", key: "reciept_amount", type: "number" },
    { label: "CBS Status", key: "cbs_status", type: "string" },
    { label: "Attempt", key: "attempt", type: "number" },
    // { label: "Deatils", key: "description", align: "right" },
  ];
  const renderList = (data, { index, setShowModal, setFormData }) => {
    return (
      <ZTableBody
        row={data}
        header={header}
        onEdit={(e, data, action) => {
          if (action == "UPDATE_RECIEPT") {
            dispatch({ type: REQUESTS.PAYMENTS.PATCH.ACTION, payload: data });
          } else if (action == "LOAN_POST") {
            dispatch({ type: REQUESTS.PAYMENTS.POST.ACTION, 
              payload: { url: "descpro/post/", payload: data },
              // no_update:true,
              // callback:(data)=>{}
             });
          } else {
            setFormData(data);
            setShowModal(true);
          }
        }}
      />
    );
  };
  const createParent = ({ children }) => {
    return (
      <div
        style={{
          height: "70vh",
          width: "100%",
          backgroundColor: "white",
          overflowY: "auto",
        }}
      >
        <ZTable total={PaymentState?.data.count}>
          <ZTableHead header={header} action={true} ></ZTableHead>
          {children ? children : null}
        </ZTable>
      </div>
    );
  };
  // viewSave Customization end
  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={3} md={12}>
            <ViewSave
              dataList={PaymentState?.data?.results}
              // showColumns={["first_name", "whatapp_number"]}
              onSubmit={onSubmit}
              title={"All payments"}
              element={createParent}
              renderList={renderList}
              renderSearch={() => <ZTableToolbar columns={header} />}
              pagination={true}
              totalPaginationCount={PaymentState?.data?.count}
              onClickPagination={onClickPagination}
            ></ViewSave>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
