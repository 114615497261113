import { updateCacheData } from "./util";
import { AUTH_DATA, LOGIN } from "../types";
// ---  Should  not update this code----i
export default function common(
  REQUESTS = {},
  MODULE_NAME = "",
  state = {},
  action
) {
  switch (action.type) {
    case "LOGOUT":
      localStorage.removeItem(AUTH_DATA);
      updateCacheData(
        LOGIN,
        {},
        {},
        action.action,
        true
      );
      return {
        ...state,
        data: {},
      };
    case "ISLOGGED":
      let auth = localStorage.getItem(AUTH_DATA);
      return {
        ...state,
        data: auth ? JSON.parse(auth) : {},
      };

    case `${MODULE_NAME}_GET` ||
      `${MODULE_NAME}_POST` ||
      `${MODULE_NAME}_PUT` ||
      `${MODULE_NAME}_PATCH` ||
      `${MODULE_NAME}_DELETE` ||
      `${MODULE_NAME}_SEARCH`:
      return {
        ...state,
        loading: true,
      };
    case MODULE_NAME + "_SUCCESS":
      let prev = { ...state.data };
      if (MODULE_NAME == LOGIN) {
        localStorage.setItem(AUTH_DATA, JSON.stringify(action.data));
        updateCacheData(
          MODULE_NAME,
          prev,
          action.data,
          action.action,
          action.cache
        );
        return {
          ...state,
          data: action.data,
          loading: false,
          success: true,
          message: "Data Successfully Saved",
        };
      }

      return {
        ...state,
        data: updateCacheData(
          MODULE_NAME,
          prev,
          action.data,
          action.action,
          action.cache
        ),
        loading: false,
        success: true,
        message: "Data Successfully Saved",
      };

    case MODULE_NAME + "_FAILED":
      let message = action.data?.message
      return {
        ...state,
        loading: false,
        message: message ? message : "Failed This Action ",
        success: false,
      };
    default:
      return state;
  }
}
// ---  Should  not update above code----
