const dev = "development";
const release = "release";

const ENV = dev; // development || release

// url

const development_Url = "https://vikasmoney.co.in/api/";
const release_url = "https://vikasmoney.co.in/api/";

export const OTP_DURATION_IN_SECONDS = 60;
export const APPLICATION_VERSION = 16;

export const baseUrl = () => (ENV === dev ? development_Url : release_url);
