import common from '../saga/reducer';
import { cachedData } from './util';
import { takeEvery } from 'redux-saga/effects';
import { setRequest, _get, _post, _put, _patch, _del, _search } from './saga'


// ---   update this bellow code----
export const createAction = (ACTION, url, reduce_name, defaultLoader) => {
    let Make = {}
    Make[ACTION] = {
        "GET": { "URL": url, "ACTION": ACTION + "_GET", "defaultLoader": defaultLoader },
        "POST": { "URL": url, "ACTION": ACTION + "_POST", "defaultLoader": defaultLoader },
        "PUT": { "URL": url, "ACTION": ACTION + "_PUT", "defaultLoader": defaultLoader },
        "PATCH": { "URL": url, "ACTION": ACTION + "_PATCH", "defaultLoader": defaultLoader },
        "DELETE": { "URL": url, "ACTION": ACTION + "_DELETE", "defaultLoader": defaultLoader },
        "SEARCH": { "ACTION": ACTION + "_SEARCH","defaultLoader": defaultLoader },
        "STATE": {
            data: cachedData(ACTION, "object"),
            loading: false,
            message: "",
            success: false,
        },
        reducer: function (state = Make[ACTION].STATE, action) {
            return common(Make, ACTION, state, action)
        },
        "reduce_name": reduce_name
    }
    return Make
}

export const createSaga = (REQUESTS) => {
    setRequest(REQUESTS)
    let sagas = Object.keys(REQUESTS).map((key) => {
        if (typeof key !== "string") return null
        let obj = {}
        obj[key] = function* () {
            yield takeEvery(REQUESTS[key].GET.ACTION, _get);
            yield takeEvery(REQUESTS[key].POST.ACTION, _post);
            yield takeEvery(REQUESTS[key].PUT.ACTION, _put);
            yield takeEvery(REQUESTS[key].PATCH.ACTION, _patch);
            yield takeEvery(REQUESTS[key].DELETE.ACTION, _del);
            yield takeEvery(REQUESTS[key].SEARCH.ACTION, _search);
        }
        return obj[key]()
    })
    return sagas
}


export const createReducer = (REQUESTS) => {
    let reduce = {}
    Object.keys(REQUESTS).map((key) => {
        if (typeof key !== "string") return null
        reduce[REQUESTS[key]["reduce_name"]] = REQUESTS[key]?.reducer
        return
    })
    return reduce
}

