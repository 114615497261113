import CropImg from "./imgcrop/CropImg";
import VS from "./ViewSave";
import P from "./Palette";
import AC from "./inputs/XAutoComplate";
import XF from "./inputs/XForm";
import XL from "./inputs/XLocation";
import XG from "./inputs/XGeoMove";
import ZCB from "./ZButton";
import Inp from "./inputs/Input";
import ZT from "./table/ZTable";
import ZF from "./inputs/ZFilter";

export const CImg = CropImg;
export const ViewSave = VS;
export const Palette = P;
export const XAutoComplete = AC;
export const XForm = XF;
export const XLocation = XL;
export const XGeoMove = XG;
export const ZButton = ZCB;
export const Input = Inp;
export const ZTable = ZT;
export const ZFilter = ZF;
export const K = (value, label) => (value ? value : label);
export const cleanUp = (obj) => {
  let result = obj;
  if (typeof obj === "object") {
    Object.keys(obj).map((key) => {
      if (
        obj[key] &&
        (obj[key] === " " || obj[key] === "" || obj[key] === undefined)
      ) {
        delete obj[key];
      }
    });
  }
  return obj;
};
