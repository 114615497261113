import React, { useRef } from "react";
import { Card, Grid, Paper, Button } from '@mui/material';
import readXlsxFile from 'read-excel-file'
import writeXlsxFile from 'write-excel-file'

const HEADER_ROW = [
  {
    value: 'Name',
    fontWeight: 'bold'
  },
  {
    value: 'Date of Birth',
    fontWeight: 'bold'
  },
  {
    value: 'Cost',
    fontWeight: 'bold'
  },
  {
    value: 'Paid',
    fontWeight: 'bold'
  }
]

const DATA_ROW_1 = [
  // "Name"
  {
    type: String,
    value: 'John Smith'
  },

  // "Date of Birth"
  {
    type: Date,
    value: new Date(),
    format: 'mm/dd/yyyy'
  },

  // "Cost"
  {
    type: Number,
    value: 1800
  },

  // "Paid"
  {
    type: Boolean,
    value: true
  }
]

const data = [
  HEADER_ROW,
  DATA_ROW_1,
]

function Message() {
  const [head, setHead] = React.useState([])
  let [rows, setRows] = React.useState([])
  let [qvalue, setQvalue] = React.useState('')
  let [sel, setSel] = React.useState(0)
  const readExcelFile = (e) => {
    readXlsxFile(e.target.files[0]).then((rows) => {
      let h = rows.shift()
      setHead(h)
      setRows(rows)
    })
  }
  function delay(t, data) {
    return new Promise(resolve => {
      setTimeout(resolve, t, data);
    });
  }

  const q = (selCol, _for) => {
    if (selCol === -2) return ""
    function cl(tr) {
      let filterRow = tr.map((c, i) => (typeof c === 'number') ? c : "'" + c + "'")
      return selCol == -1 ? filterRow.join(",") : filterRow[selCol]
    }
    if (_for == 0) {
      return rows.map((tr, i) => "(" + cl(tr) + ")").join(",")
    }
    if (_for == 1) {
      return rows.map((tr, i) => cl(tr)).join(",")
    }
  }


  const exportExcel = async () => {
    let exportHeader = []
    exportHeader.push({ value: "SL NO", fontWeight: 'bold' })
    head.map((value) => {
      exportHeader.push({ value: value, fontWeight: 'bold' })
    })
    exportHeader.push({ value: "Status", fontWeight: 'bold' })
    let hasRow = false
    let rows = [exportHeader]
    // contacts.map((contact, i) => {
    //   hasRow = true
    //   let cols = []
    //   cols.push({ column: 'SL NO.', type: String, value: String(i + 1) })
    //   contact.map((c, j) => {
    //     cols.push({ column: head[j], type: String, value: String(c) })
    //   })
    //   cols.push({ column: 'Status', type: String, value: status[contact[0]] ? "Success" : "Pending" })
    //   rows.push(cols)

    // })
    // console.log(rows)
    let dt = new Date().toLocaleString().replace(',', '')
    await writeXlsxFile(rows, {
      //columns, // (optional) column widths, etc.
      fileName: `message_report_${dt}.xlsx`
    })
  }
  return (
    <>
      <Grid container>
        <Grid>
          <Grid item md={3} xs={3}>
            <input type="file" id="input" onChange={readExcelFile} className="form-control form-control-md" />
          </Grid>
          <Grid item md={3} xs={3}>
            <input type="button" value={"copy"} />
            <select onChange={(e) => { setQvalue(q(e.target.value, 0));setSel(e.target.value) }}>
              <option value={-2}>Select</option>
              <option value={-1}>All</option>
              {head.map((th, i) => <option key={i} value={i}>{th}</option>)}
            </select>
            <select onChange={(e) => { setQvalue(q(sel, e.target.value))}}>
              <option value={-2}>Select</option>
              <option value={0}>Insert</option>
              <option value={1}>Select</option>
              <option value={2}>Update</option>
            </select>
            <textarea value={qvalue} onChange={(e) => setQvalue(e.target.value)}> </textarea>
          </Grid>
        </Grid>
        <Grid item md={12}>
          <table>
            <thead>
              <tr>
                <th>SL</th>
                {head.map((th, i) => <th key={i}>{th}</th>)}
              </tr>
            </thead>
            <tbody>
              {rows.map((tr, i) => <tr key={i}><th>{i + 1}</th>{tr?.map((td, j) => <td key={j}>{td}</td>)}</tr>)}
            </tbody>
          </table>
        </Grid>
      </Grid>
    </>
  );
}

export default Message;