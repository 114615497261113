import * as React from "react";
import {
  FormControlLabel,
  Checkbox,
  TextField,
  Button,
  Grid,
  Paper,
  Avatar,
  Typography,
  Link,
} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { XForm, ZButton } from "../../libs/Libs";
import { LOGIN_REQUEST, REQUESTS } from "../../redux/types";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LinearProgress from "@mui/material/LinearProgress";
import { useState } from "react";

const Login = ({ title, hasForgot, hasRegister, submitLabel }) => {
  title = title ? title : "Sign In";
  submitLabel = submitLabel ? submitLabel : "Sign In";
  let navigate = useNavigate();
  let dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const paperStyle = {
    padding: 20,
    height: "70vh",
    width: 380,
    margin: "20px auto",
  };
  const avatarStyle = { backgroundColor: "#1095bbfc" };
  const btnStyle = { margin: "8px 0px" };
  return (
    <Grid>
      <Paper elevation={10} style={paperStyle}>
        <Grid align="center">
          {loading && <LinearProgress />}
          <Avatar style={avatarStyle}>
            <LockOutlinedIcon />
          </Avatar>
          <h2>{title}</h2>
        </Grid>

        <XForm
          onSubmit={(e, data) => {
            setLoading(true);
            dispatch({
              type: REQUESTS.LOGIN.POST.ACTION,
              payload: data,
              cache: true,
              callback: (data) => {
                setLoading(false);
                if (data.token) {
                  if (data.last_login) {
                    navigate("/dashboard/");
                  } else {
                    navigate("/register/");
                  }
                }
              },
            });
          }}
        >
          <TextField
            fullWidth
            required
            variant="standard"
            label="Username"
            name="username"
            placeholder="Enter Username"
          />
          <TextField
            fullWidth
            required
            variant="standard"
            label="Password"
            name="password"
            placeholder="Enter Password"
            type="password"
          />

          <FormControlLabel
            control={<Checkbox defaultChecked />}
            label="Remember Me"
          />

          {/* <Button
            fullWidth
            variant="contained"
            type="submit"
            color="primary"
            style={btnStyle}
          >
            {submitLabel}
          </Button> */}
          <ZButton
            fullWidth
            loading={loading}
            title={submitLabel}
            bgcolor={"green"}
            type={"submit"}
          />
        </XForm>

        {hasForgot && (
          <Typography>
            <Link href="#">Forgot Password ?</Link>
          </Typography>
        )}

        {hasRegister && (
          <Typography>
            {" "}
            Do you have an account ?<Link href="/register/">Sign Up</Link>
          </Typography>
        )}
      </Paper>
    </Grid>
  );
};
export default Login;
