import SpaceDashboardSharpIcon from "@mui/icons-material/SpaceDashboardSharp";
import LineStyleSharpIcon from "@mui/icons-material/LineStyleSharp";

import Dashboard from "../pages/dashboard/Home";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Profile from "../pages/profile/Profile";
import Payments from "../pages/upi_app/payments";
import LoanPayPage from "../pages/upi_app/LoanPayPage";

import PaletteProject from "../pages/palette/Projects";
import PaletteComponent from "../pages/palette/Component";
import PaletteProperty from "../pages/palette/Property";

import Home from "../pages/tasks/Home";
import Comment from "../pages/tasks/Comment";
import Message from "../pages/csv/Message";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import GroupIcon from "@mui/icons-material/Group";
import UpcomingTask from "../pages/tasks/UpcomingTask";
import MyTask from "../pages/tasks/MyTask";
import FeedIcon from "@mui/icons-material/Feed";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import Documents from "../pages/docs/Document";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

export const HomePage = LoanPayPage; //its shows as loagout page
export const LoggedoutPages = ["/login","/pay","/login/","/pay/"]

export const menuItems = [
  {
    name: "Dashboard",
    exact: true,
    to: "/dashboard/",
    icon: <SpaceDashboardSharpIcon />,
    component: <Dashboard />,
  },

  {
    name: "Payments",
    exact: true,
    to: "/payments/",
    icon: <ArticleOutlinedIcon />,
    component: <Payments />,
    // noMenu: true,
  },
  {
    name: "LoanPayments",
    exact: true,
    to: "/pay/",
    icon: <ArticleOutlinedIcon />,
    component: <LoanPayPage />,
    noMenu: true,
  },
];
