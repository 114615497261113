import * as React from "react";
import "./style.css";

export default function XAutoComplete({
  style,
  options,
  value,
  text,
  label,
  name,
  defaultValue,
}) {
  const ref = React.createRef();
  const [c, setC] = React.useState();
  const [cLabel, setCLabel] = React.useState();
  value = value ? value : "value";
  text = text ? text : "text";
  style = style ? style : {};
  React.useEffect(() => {
    console.log("DV", defaultValue);
    try {
      setC(defaultValue[value]);
      // setCLabel(defaultValue[text])
      ref.current.value = defaultValue[text];
    } catch {
      setC("");
      // setCLabel("")
      ref.current.value = "";
    }
  }, [defaultValue]);
  React.useEffect(() => {}, [c]);

  function autocomplete(inp, arr, text, value) {
    /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
    var currentFocus;
    /*execute a function when someone writes in the text field:*/
    inp.addEventListener("input", function (e) {
      var a,
        b,
        i,
        val = this.value;
      /*close any already open lists of autocompleted values*/
      closeAllLists();
      if (!val) {
        return false;
      }
      currentFocus = -1;
      /*create a DIV element that will contain the items (values):*/
      a = document.createElement("DIV");
      a.setAttribute("id", this.id + "autocomplete-list");
      a.setAttribute("class", "autocomplete-items");
      /*append the DIV element as a child of the autocomplete container:*/
      this.parentNode.appendChild(a);
      /*for each item in the array...*/
      for (i = 0; i < arr.length; i++) {
        /*check if the item starts with the same letters as the text field value:*/
        let t = arr[i][text] ? String(arr[i][text]) : String(arr[i]);
        let v = arr[i][value] ? arr[i][value] : arr[i];
        if (t.substr(0, val.length).toUpperCase() == val.toUpperCase()) {
          /*create a DIV element for each matching element:*/
          b = document.createElement("DIV");
          /*make the matching letters bold:*/

          b.innerHTML = "<strong>" + t?.substr(0, val.length) + "</strong>";
          b.innerHTML += t?.substr(val.length);

          /*insert a input field that will hold the current array item's value:*/
          b.innerHTML += "<input type='hidden' value='" + v + "'>";
          b.innerHTML += "<input type='hidden' value='" + t + "'>";
          /*execute a function when someone clicks on the item value (DIV element):*/
          b.addEventListener("click", function (e) {
            /*insert the value for the autocomplete text field:*/
            setC(this.getElementsByTagName("input")[0].value);
            inp.value = this.getElementsByTagName("input")[1].value;
            /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
            closeAllLists();
          });
          a.appendChild(b);
        }
      }
    });
    /*execute a function presses a key on the keyboard:*/
    inp.addEventListener("keydown", function (e) {
      var x = document.getElementById(this.id + "autocomplete-list");
      if (x) x = x.getElementsByTagName("div");
      if (e.keyCode == 40) {
        /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
        currentFocus++;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 38) {
        //up
        /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
        currentFocus--;
        /*and and make the current item more visible:*/
        addActive(x);
      } else if (e.keyCode == 13) {
        /*If the ENTER key is pressed, prevent the form from being submitted,*/
        e.preventDefault();
        if (currentFocus > -1) {
          /*and simulate a click on the "active" item:*/
          if (x) x[currentFocus].click();
        }
      }
    });
    function addActive(x) {
      /*a function to classify an item as "active":*/
      if (!x) return false;
      /*start by removing the "active" class on all items:*/
      removeActive(x);
      if (currentFocus >= x.length) currentFocus = 0;
      if (currentFocus < 0) currentFocus = x.length - 1;
      /*add class "autocomplete-active":*/
      x[currentFocus].classList.add("autocomplete-active");
    }
    function removeActive(x) {
      /*a function to remove the "active" class from all autocomplete items:*/
      for (var i = 0; i < x.length; i++) {
        x[i].classList.remove("autocomplete-active");
      }
    }
    function closeAllLists(elmnt) {
      /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
      var x = document.getElementsByClassName("autocomplete-items");
      for (var i = 0; i < x.length; i++) {
        if (elmnt != x[i] && elmnt != inp) {
          x[i].parentNode.removeChild(x[i]);
        }
      }
    }
    /*execute a function when someone clicks in the document:*/
    document.addEventListener("click", function (e) {
      closeAllLists(e.target);
    });
  }
  React.useEffect(() => {
    var objectConstructor = {}.constructor;
    let list = [];
    if (options && options.length > 0) {
      let isValid = options[0].constructor === objectConstructor ? true : false;
      if (!isValid) {
        list = options.map((data, i) => {
          return { value: i, text: data };
        });
      } else {
        list = options;
      }
      autocomplete(ref.current, list, text, value);
    }
  }, [ref.current]);

  const rener = (e) => {
    let sel = options?.filter((o) => o[text] == e.target.value);
    if (sel.length > 0) {
      ref.current.value = sel[0][text];
      setC(sel[0][value]);
    }
    e.preventDefault();
  };

  return (
    <>
      <div
        class="autocomplete"
        style={{ width: style?.width, height: style?.height }}
      >
        <input
          defaultValue={cLabel}
          ref={ref}
          style={{ ...style }}
          placeholder={label}
        />
        <input
          defaultValue={c}
          value={c}
          style={{ display: "none" }}
          name={name}
          onInput={(e) => {
            rener(e);
          }}
          type={"text"}
        />
      </div>
    </>
  );
}
