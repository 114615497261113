import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Card, Grid } from "@mui/material";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../redux/types";
import { Modal, Box } from '@mui/material';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};
const bottomStyle = {
  textAlign: 'center',
  position: 'fixed',
  bottom: '0px',
  left: '0px',
  right: '0px',
  margin: 'auto',
  color: '#335f78',
  width: '100%',
  background: '#9db4c1',
  fontSize: '12px',
  padding: 2
}

export default function LoanPayPage() {
  let dispatch = useDispatch();
  let loanDetailsState = useSelector((state) => state.loan_details?.data);
  let [searchInput, setSearchInput] = React.useState("");
  let [label, setLabel] = React.useState("Phone Number");
  let [open, setOpen] = React.useState(false);
  let [qrcode, setQrCode] = React.useState("");
  let ref = React.useRef()

  const fetchData = async () => {
    try {
      searchInput = ref.current.checked ? searchInput : "mob" + searchInput
      dispatch({
        type: REQUESTS.LOAN_DETAILS.GET.ACTION,
        payload: `search_loan/${searchInput}/`,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const handlePayButtonClick = (loanNumber, name) => {
    console.log("lOAN NUMBER", loanNumber);
    const upiPaymentLink = `upi://pay?pa=vikasmoneyupi.${loanNumber}@icici&mc=vikasmoney.co.in&pn=${name}&cu=INR`;
    window.location.href = upiPaymentLink;
    console.log("UPI:", upiPaymentLink)
  };
  console.log("LOAN DETAIL", loanDetailsState?.results);

  return (
    <>
      <Modal
        open={open}
        onClose={() => { setOpen(false); setQrCode(null) }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Scan Qr code
          </Typography>
          <img src={qrcode} width={"100%"} />
        </Box>
      </Modal>

      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        // justifyContent="center"
        sx={{ minHeight: '100vh' }}
      >
        <Grid item xs={12} md={12} xl={4} sx={{ borderRadius: 2, p: 1 }}>

          <div>
            <span style={{ fontSize: 12 }}>
              <input type="checkbox" ref={ref} onClick={(e) => setLabel(e.target.checked ? "Search Loan Number" : "Phone Number")} />
              Use Loan Number
            </span>
            <TextField
              label={label}
              variant="outlined"
              value={searchInput}
              fullWidth
              size="small"
              onChange={(e) => setSearchInput(e.target.value)}
              autoComplete="true"
            />
          </div>
          <div>
            <Button
              size="small"
              variant="contained"
              onClick={() => fetchData()}
              style={{ marginTop: "8px", alignItems: "center" }}
            >
              Search
            </Button>
          </div>
          <div style={{ height: 1, background: '#2b5d79', marginTop: 10 }}></div>
          {loanDetailsState?.results?.length <= 0 && <span style={{ fontSize: 10 }}>No data available</span>}
          <div>
            {loanDetailsState?.results?.filter((item, index) => index < 2).map((loan) =>
              <Card sx={{ width: '100%', marginTop: 1 }}>
                {loan?.customer?.photo && <Avatar alt={loan?.customer?.customer_full_name} src={loan?.customer?.photo} />}
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {loan?.customer?.customer_full_name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {loan?.customer?.phoneNumber && <span>Phone Number: {loan?.customer?.phoneNumber}</span>}
                  </Typography>
                </CardContent>
              </Card>)}
          </div>
          <div>
            {loanDetailsState?.results?.length > 0 && (
              <div style={{ marginTop: 2 }}>
                <Typography variant="h6" gutterBottom>
                  Loan Numbers
                </Typography>
                <List
                  sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
                >
                  {loanDetailsState?.results?.map((obj) => (
                    <ListItem
                      key={obj.loan_unique_no}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginRight: 2,
                      }}
                    >
                      <ListItemText primary={obj.loan_unique_no} />
                      <Button onClick={() => handlePayButtonClick(obj.loan_unique_no, obj.customer.customer_full_name)}>Pay</Button>
                      <Button onClick={(e) => { setQrCode(obj.qr_code); setOpen(true) }}>QR</Button>
                    </ListItem>
                  ))}
                </List>
              </div>
            )}
          </div>
          <div style={bottomStyle}>
            <div style={{ fontSize: 16 }}>Vikas Money Online Payment portal</div>
            <a style={{ color: '#1795dd' }} href="https://www.vikasmoney.com/terms&conditions.html">Terms & Conditions</a>
            <a style={{ color: '#1795dd' }} href="https://www.vikasmoney.com/privacy-policy.html"> | Privacy Policy</a>
            <a style={{ color: '#1795dd' }} href="https://www.vikasmoney.com/disclaimer.html"> | Disclaimer</a>
            <div>Copyright © vikasmoney.com 2024.</div>
            <span>Powerd by: Zourcelab private Limited</span>
          </div>
        </Grid>
      </Grid>
    </>
  );
}
