import React from 'react';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import MoreVertSharp from '@mui/icons-material/MoreVertSharp';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';

export default function XMenu({list,onClick,anchorOrigin,more}) {
    onClick = onClick?onClick:()=>{}
    const [anchorElUser, setAnchorElUser] = React.useState(null);
   
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <>

            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {more && <MoreVertSharp/>}
                {!more && <Avatar alt="Remy Sharp" sx={{ width: 24, height: 24 }} src="/static/images/avatar/2.jpg" />}
            </IconButton>

            <Menu
                sx={{ mt: '45px' }}
                anchorEl={anchorElUser}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
            >
                {list && list.map((setting) => (
                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                        <Typography textAlign="center" onClick={(e)=>{onClick(e,setting)}}>{setting}</Typography>
                    </MenuItem>
                ))}
            </Menu>
        </>
    )
}