import React, { useEffect } from "react";
import "./App.css";
import Layout from "./layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import axios from "axios";
import { REQUESTS } from "./redux/types";
import { LoggedoutPages } from "./layout/menus"

function App() {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let auth = useSelector((state) => state.auth);
  const authentic = () => {
    console.log("AUTH:", auth);
    if (auth && auth.data && auth.data.token) {
      axios.defaults.headers.common["Authorization"] =
        "Token " + auth.data.token;
      let path = localStorage.lastPath ? localStorage.lastPath : "/dashboard";
      // navigate("/dashboard")
    } else {
      axios.defaults.headers.common["Authorization"] = null;
      if (LoggedoutPages.indexOf(window.location.pathname) != -1) {
        navigate(window.location.pathname);
      } else {
        navigate("/");
      }
    }
  };
  React.useEffect(() => {
    authentic();
    dispatch({ type: REQUESTS.DB_PLAN.GET.ACTION, payload: 0 });
  }, []);
  // useEffect(() => {
  //   if (window.location.pathname.length>3){
  //     localStorage.lastPath = window.location.pathname
  //   }
  // }, [window.location.href])
  React.useEffect(() => {
    authentic();
  }, [auth]);
  return (
    <>
      <Layout
        isLogged={auth?.data?.token}
        name={"UPI PAYMENT PORTAL"}

      ></Layout>
    </>
  );
}
export default App;
