import * as React from 'react';
import Box from '@mui/material/Box';
import { TextField, Button } from '@mui/material';

export default function XForm({ data, onSubmit, children }) {
  onSubmit = onSubmit ? onSubmit : () => { }
  const ref = React.createRef()
  const [id, setId] = React.useState()
  const [validated, setValidated] = React.useState(false);
  const childrenRef = React.useRef([]);
  React.useEffect(() => {
    setId('')
    refreshForm(data || {})
  }, [data])

  const refreshForm = (data) => {
    // console.log("Form Data", JSON.stringify(data))
    if (data && data.length !== 0) {
      let form = ref.current
      for (let i in form.elements) {
        if (form.elements[i].name !== undefined && data[form.elements[i].name] && form.elements[i].value !== undefined && form.elements[i].name !== "") {
          if (form.elements[i].type === "file") {
          } else {
            try {
              let node = form.elements[i]
              const descriptor = Object.getOwnPropertyDescriptor(node, 'value');
              if (descriptor && descriptor.configurable) {
                delete node.value;
              }
              node.value = data[form.elements[i].name];
              let event = new Event('input', { 'bubbles': true })
              node.dispatchEvent(event);
              if (descriptor) {
                Object.defineProperty(node, 'value', descriptor);
              }
              event = new Event('click', { 'bubbles': true })
              node.dispatchEvent(event);
            } catch (err) { }
          }
        }
      }
      setId(data["id"]);
    } else {
      setId('');
    }
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      setValidated(true);
      return false;
    }
    let data = {}
    let formData = new FormData()
    for (let i in form.elements) {

      if (form.elements[i].name !== undefined && form.elements[i].value !== undefined && form.elements[i].name !== "") {
        if (form.elements[i].type === "file") {
          if (form.elements[i].files.length !== 0) {
            formData.append(form.elements[i].name, form.elements[i].files[0]);
          }
        } else {
          data[form.elements[i].name] = form.elements[i].value
        }
        if (form.elements[i].multiple && form.elements[i].tagName === 'SELECT') {
          data[form.elements[i].name] = Array.from(form.elements[i].selectedOptions, (option) => option.value);
          formData.append(form.elements[i].name, Array.from(form.elements[i].selectedOptions, (option) => option.value));
        }
      }
    }
    if (id) data["id"] = id
    onSubmit(event, data)
  }
  return (

    <Box
      component="form"
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      ref={ref}
    >
      {children}
      {/* {React.Children.map(children , (child, index) =>
        // React.cloneElement(child, {
        //   ref: (ref) => (childrenRef.current[index] = ref),
        //   init:1234,
        //   value:"hello",
        //   options:[]
        // })
        // <input key={index}/>
      )} */}
    </Box>
  );
}