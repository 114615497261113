import * as React from "react";
import { alpha } from "@mui/material/styles";
import Table from "@mui/material/Table";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TableBody from "@mui/material/TableBody";
import styled from "styled-components";
// import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterList";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { XForm, ZFilter } from "../Libs";
import { css } from '../../content/Style'
import ZMenu from '../ZMenu'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme?.palette?.common?.black,
    color: theme?.palette?.common?.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    paddingBottom: 2,
    paddingTop: 0,
    paddingRight: 0
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette?.action?.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const fetchColor = (head, value) => {
  if (value == "SUCCESS" || value == "COMPLETED") {
    return css?.Btn?.Success
  }
  if (value == "FAILURE" || value == "FAILED") {
    return css?.Btn?.Delete
  }
  if (value == "PENDING") {
    return css?.Btn?.Edit
  }
  if (value == "LOAN NOT FOUND") {
    return css?.Btn?.Warn
  }

}

const popUp = (data, key, onEdit) => {
  let value = data[key]
  if (value == "LOAN NOT FOUND") {
    return <ZMenu>
      <XForm onSubmit={(e, data) => onEdit(e, data, "LOAN_POST")}>
        <div style={{ padding: 4 }}>
          <input value={data["id"]} name="id" type="hidden" />
          <textarea placeholder="Enter loan" name="loan_number"></textarea>
          <div style={{ textAlign: 'right' }}>
            <button>Submit</button>
          </div>
        </div>
      </XForm>
    </ZMenu>
  }
  if (value == "" && key == "reciept_no") {
    return <ZMenu>
      <XForm onSubmit={(e, data) => onEdit(e, data, "UPDATE_RECIEPT")}>
        <div style={{ padding: 4 }}>
          <input value={data["id"]} name="id"  type="hidden" />
          <div> <input placeholder="Enter Reciept Number" name="reciept_no" /> </div>
          <div> <input placeholder="Enter Reciept Date" name="reciept_date" /> </div>
          <div> <input placeholder="Enter Reciept Amount" name="reciept_amount" /> </div>
          <div style={{ textAlign: 'right' }}>
            <button>Submit</button>
          </div>
        </div>
      </XForm>
    </ZMenu>
  }

}

// toolbar
export function ZTableToolbar({ columns }, props) {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Search & Filter
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="">
          <IconButton>
            <ZFilter columns={columns} icon={<FilterListIcon />} />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
}
// end toolbar

// Toolbar Menu
export function ToolbarMenu() { }
// Toolbar menu End
export function ZTableHead({ header, action, total }) {
  return (
    <>
      <TableHead>
        <TableRow
          style={{
            backgroundColor: "#e1e5ee",
          }}
        >
          {header?.map((head, i) => (
            <TableCell sx={{ top: 0, position: 'sticky' }} align={head?.align}>{head?.label}</TableCell>
          ))}
          {action && <TableCell sx={{ top: 0, position: 'sticky' }} align="right">Action</TableCell>}
        </TableRow>
      </TableHead>
    </>
  );
}
// table body
export function ZTableBody({ row, header, onEdit }) {
  return (
    <StyledTableRow
      key={row?.name}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      {header?.map((head, i) => (
        <StyledTableCell align={head?.align} sx={{ ...css.Table.Cell, ...fetchColor(head, row[head?.key]) }}>
          {head?.key in row ? row[head?.key] : null}
          {popUp(row, head?.key, onEdit)}
        </StyledTableCell>
      ))}

      {onEdit && (
        <StyledTableCell align="right">
          <span onClick={onEdit}>Edit</span>
        </StyledTableCell>
      )}
    </StyledTableRow>
  );
}

export default function ZTable({ total, children }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        {children}
      </Table>
    </TableContainer>
  );
}
