import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Box, Button, Divider, Grid, Stack } from '@mui/material';
import Input from './Input';
import { XForm } from '../Libs';
export default function ZFilter({ icon, columns, operators, onChange }) {
    icon = icon ? icon : "Search & Filter"
    const [types, setTypes] = React.useState([])
    onChange = onChange ? onChange : (e, data) => { console.log("SUBMIT DATA", data) }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    columns = columns ? columns : columns = [
        { label: "ID", type: "number" },
        { label: "Name", type: "string" },
        { label: "Payment Status", type: "string" },
        { label: "Date", type: "number" },
    ];
    operators = operators ? operators : operators = {
        number: ["=", ">", "<", "!=", "", "Is Empty", "Is Not Empty", "Is Any Of"],
        string: [
            "Contains",
            "Equals",
            "Start With",
            "End With",
            "Is Empty",
            "Is Not Empty",
            "Is Any Of",
        ],
    };


    return (
        <div>
            <span style={{ cursor: "pointer" }} onClick={handleClick}>{icon}</span>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box sx={{ p: 2 }}>
                    <XForm onSubmit={onChange}>
                        <Divider textAlign="left" sx={{ fontSize: 10 }}>Search</Divider>
                        <Stack sx={{ my: 2 }} spacing={1} direction={"row"} divider={<Divider orientation="vertical" flexItem />}>
                            <Input type="select" style={{ width: 100 }}
                                name={"name"} label={"Column"}
                                options={columns} text={"label"}
                                onChange={(e) => {
                                    console.log(e.target.value, e.target.options[e.target.selectedIndex].text)
                                    setTypes([...operators[e.target.value]])
                                }
                                }
                                val={"type"} />
                            <Input type="select" style={{ width: 100 }}
                                name={"operator"} label={"Column"}
                                options={types}
                                onChange={(e) => { console.log(e.target.value, e.target.options[e.target.selectedIndex].text) }} />
                            <Input type="text" name={"value"} label={"Value"} style={{ width: '100%' }} />
                        </Stack>
                        {/* <Divider textAlign="left" sx={{ fontSize: 10 }}>Filter</Divider>
                    <Stack spacing={1} direction={"row"} divider={<Divider orientation="vertical" flexItem />}>
                        <Input type="select" style={{ width: 100 }} />
                        <XMultiSelect/>
                    </Stack> */}
                        <Stack spacing={1} sx={{ my: 1 }} direction={"row"} divider={<Divider orientation="vertical" flexItem />}>
                            <Button type={"Submit"}>Filter</Button>
                        </Stack>
                    </XForm>
                </Box>
            </Menu>
        </div>
    );
}