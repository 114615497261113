const bgColor = '#335F78'
const border = '1px solid #d9e3f0'
const color = 'black'
export const css = {
    AppBar: {
        backgroundColor: bgColor,
        border: border,
        height: 40,
        color: 'white',
        Chip:{
            color: 'white',
        }
    },
    SideBar: {
        backgroundColor: '#d9dcde',
        border: 'none',
        color: 'red',
        iconColor: bgColor,
        iconSize: '16px',
    },
    Card: {
        header: {
            backgroundColor: bgColor,
            border: border,
            color: 'white',
            borderRadius: '6px 6px 0 0',
            opacity: 0.6,
        },
        body: {
            border: border,
        },
        footer: {

        },
        list: {

        }
    },
    Table: {
        header: {
            bacnkgroundColor: bgColor,
            border: border,
            color: color
        },
        Cell:{
            color:'black',
            padding:0,
            paddingLeft:1,
            textOverflow: 'ellipsis',
            fontSize:13
        }
    },
    Btn: {
        Edit: {
            fontSize: 12,
            color: '#299de1',
        },
        Delete: {
            fontSize: 12,
            color: '#f53232',
            mx:1
        },
        Warn: {
            fontSize: 13,
            color: '#ffbc00'
        },
        Success: {
            fontSize: 13,
            color: 'green'
        }
    },
    Content:{
        small:{
            fontSize:12,
            color:'gray'
        }
    }
}