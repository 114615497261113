import * as React from "react";
import { TextField } from "@mui/material";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { REQUESTS } from "../../redux/types";
import { XAutoComplete, ViewSave } from "../../libs/Libs";
import CropImg from "../../libs/imgcrop/CropImg";

export default function Home() {
  let dispatch = useDispatch();
  let userState = useSelector((state) => state.user);
  let [paginate, setPaginate] = React.useState({});
  let [filter, setFilter] = React.useState([]);

  const [q, setQ] = React.useState({
    model: "user",
  });

  const init = (q) => {
    dispatch({ type: REQUESTS.USER.GET.ACTION, payload: 0 });
    console.log("USER SATE", userState);
    // setPaginate({ count: userState.data.count, next: userState.data.next, previous: userState.data.previous })
  };
  const onChangeQuery = (q) => {
    q = JSON.stringify({
      model: "user",
      order_by: "-created_at",
    });
    q = JSON.stringify(q);
    dispatch({ type: REQUESTS.USER.GET.ACTION, payload: `report/0/${q}/` });
  };
  React.useEffect(() => {
    setQ({
      model: "user",
    });
    init(q);
  }, []);
  React.useEffect(() => {
    console.log("UPDATED", userState);
    // setPaginate({ count: userState.data.count, next: userState.data.next, previous: userState.data.previous })
  }, [userState]);

  const onSubmit = (e, data, setLoading, setMessage) => {
    setLoading(true);
    console.log(data["id"]);
    if (data["id"] > 0) {
      console.log("UPDATING  DATA:", data);
      dispatch({
        type: REQUESTS.USER.PUT.ACTION,
        payload: data,
      });
    } else {
      console.log("POSTING  DATA:", data);
      dispatch({
        type: REQUESTS.USER.POST.ACTION,
        payload: data,
      });
    }
    setLoading(false);
  };

  const cvrt = (num) => {
    return num ? (num != 0 ? num : 0) : 0;
  };

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={3} md={12}>
            <ViewSave
              dataList={userState?.data?.results}
              // showColumns={["first_name", "whatapp_number"]}
              onSubmit={onSubmit}
              title={"Employees"}
            >
              <div style={{ paddingTop: 10 }}>
                <Box sx={{ flexGrow: 1, mt: 1, maxWidth: 300 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CropImg name={"avatar"} type={"avatar"} />
                      <XAutoComplete
                        options={userState?.data?.results}
                        text={"first_name"}
                        value={"id"}
                        label="first name"
                        name="first_name"
                        size="small"
                        style={{ width: "100%", my: 4 }}
                      />
                      <TextField
                        fullWidth
                        name={"dob"}
                        type={"date"}
                        defaultValue={"2023-10-30"}
                        label={"dob"}
                        size={"small"}
                        sx={{ my: 1 }}
                      />
                      <TextField
                        fullWidth
                        name={"whatsapp_number"}
                        label={"Whatsapp number"}
                        size={"small"}
                        sx={{ my: 1 }}
                      />
                      <TextField
                        fullWidth
                        name={"username"}
                        type={"text"}
                        label={"username"}
                        size={"small"}
                        sx={{ my: 1 }}
                        readonly={"true"}
                      />
                      <TextField
                        fullWidth
                        type={"password"}
                        name={"password"}
                        label={"password"}
                        size={"small"}
                        sx={{ my: 1 }}
                      />
                      <TextField
                        fullWidth
                        name={"last_name"}
                        type={"text"}
                        label={"last_name"}
                        size={"small"}
                        sx={{ my: 1 }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </div>
            </ViewSave>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
